export function initStripe() {
  let sepaPayment = document.querySelector("#sepa-details");
  let creditPayment = document.querySelector("#card-details");
  if (sepaPayment !== null) {
    let switchSepaPaymentButton = document.getElementById("switch-sepa");
    let switchCardPaymentButton = document.getElementById("switch-card");

    if (switchCardPaymentButton !== null && switchSepaPaymentButton !== null) {
      switchCardPaymentButton.addEventListener("click", () => {
        let sepaDisplay = !sepaPayment.classList.contains("d-none");
        if (sepaDisplay) {
          creditPayment.classList.toggle("d-none");
          sepaPayment.classList.toggle("d-none");
          switchCardPaymentButton.classList.toggle("selected")
          switchSepaPaymentButton.classList.toggle("selected")
        }
      });

      switchSepaPaymentButton.addEventListener("click", () => {
        let cardDisplay = !creditPayment.classList.contains("d-none");
        if (cardDisplay) {
          creditPayment.classList.toggle("d-none");
          sepaPayment.classList.toggle("d-none");
          switchCardPaymentButton.classList.toggle("selected")
          switchSepaPaymentButton.classList.toggle("selected")
        }
      });

    }
  }

  let cardElement = document.querySelector("#card-element");
  let ibanElement = document.querySelector("#iban-element");
  if (cardElement !== null || ibanElement !== null) {
    setupStripe();
  }
}

const setupStripe = () => {
  let iban   = null;
  let stripe = null;
  let card   = null;

  let newCard = document.querySelector("#use-new-card");
  if (newCard !== null) {
    newCard.addEventListener("click", (event) => {
      event.preventDefault();
      document.querySelector("#payment-form").classList.remove("d-none");
      document.querySelector("#existing-card").classList.add("d-none");
    });
  }

  if ($("#card-element").length || $("#iban-element").length) {
    const stripeKey = document
      .querySelector("meta[name='stripe-key']")
      .getAttribute("content");
    stripe = Stripe(stripeKey);
    const elements = stripe.elements();

    card = elements.create("card");
    card.mount("#card-element");
    let displayError = document.getElementById("card-errors");
    card.addEventListener("change", (event) => {
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = "";
      }
    });

    const style = {
      base: {
        color: "#32325d",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
        ":-webkit-autofill": {
          color: "#32325d",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
        ":-webkit-autofill": {
          color: "#fa755a",
        },
      },
    };

    const options = {
      style: style,
      supportedCountries: ["SEPA"],
      placeholderCountry: "FR",
    };

    iban = elements.create("iban", options);
    iban.mount("#iban-element");

    let displaySepaError = document.getElementById("sepa-errors");

    iban.addEventListener("change", (event) => {
      if (event.error) {
        displaySepaError.textContent = event.error.message;
      } else {
        displaySepaError.textContent = "";
      }
    });
  }

  const form = document.querySelector("#payment-form");

  if (form.length) {
    let paymentIntentId = form.dataset.paymentIntent;
    let setupIntentId = form.dataset.setupIntent;
    // The following piece of code is used when redirected to the payment page
    if (paymentIntentId) {
      let paymentMethodId = form.dataset.paymentMethodId;
      if (form.dataset.status == "requires_action") {
        if (form.dataset.paymentMethodType == "card") {
          stripe
            .confirmCardPayment(paymentIntentId, {
              setup_future_usage: "off_session",
            })
            .then((result) => {
              if (result.error) {
                displayError.textContent = result.error.message;
                form.querySelector("#card-details").classList.remove("d-none");
              } else {
                form.submit();
              }
            });
        } else {
          stripe
            .confirmSepaDebitPayment(paymentIntentId, {
              payment_method: paymentMethodId,
              setup_future_usage: "off_session",
              save_payment_method: true,
            })
            .then((result) => {
              if (result.error) {
                displayError.textContent = result.error.message;
                form.querySelector("#sepa-details").classList.remove("d-none");
              } else {
                form.submit();
              }
            });
        }
      }
    }

    form.addEventListener("submit", (event) => {
      event.preventDefault();
      let creditPayment = document.querySelector("#card-details");
      if (creditPayment.classList.contains("d-none")) {
        let accountholderName = document.querySelector("#accountholder_name")
          .value;
        let email = document.querySelector("#email").value;
        let data = {
          payment_method_data: {
            sepa_debit: iban,
            billing_details: {
              name: accountholderName,
              email: email,
            },
          },
        };
        if (paymentIntentId) {
          let paymentMethodId = form.dataset.paymentMethodId;
          stripe
            .confirmSepaDebitPayment(paymentIntentId, {
              payment_method: paymentMethodId,
              setup_future_usage: "off_session",
              save_payment_method: true,
            })
            .then((result) => {
              if (result.error) {
                let sepaError = document.querySelector("#sepa-errors");
                sepaError.textContent = result.error.message;
              } else {
                form.submit();
              }
            });
        } else if (setupIntentId) {
          stripe
            .confirmSepaDebitSetup(setupIntentId, {
              payment_method: data.payment_method_data,
            })
            .then((result) => {
              if (result.error) {
                let sepaError = document.querySelector("#sepa-errors");
                sepaError.textContent = result.error.message;
              } else {
                addHiddenField(
                  form,
                  "payment_method_id",
                  result.setupIntent.payment_method
                );
                form.submit();
              }
            });
        } else {
          data.payment_method_data.type = "sepa_debit";
          stripe.createPaymentMethod(data.payment_method_data).then((result) => {
            if (result.error) {
              displayError.textContent = result.error.message;
            } else {
              addHiddenField(form, "payment_method_id", result.paymentMethod.id);
              form.submit();
            }
          });
        }
      } else {
        let name = form.querySelector("#name_on_card").value;
        let data = {
          payment_method_data: {
            card: card,
            billing_details: {
              name: name,
            },
          },
        };
        // Complete a payment intent
        if (paymentIntentId) {
          stripe
            .confirmCardPayment(paymentIntentId, {
              payment_method: data.payment_method_data,
              setup_future_usage: "off_session",
              save_payment_method: true,
            })
            .then((result) => {
              if (result.error) {
                displayError.textContent = result.error.message;
                form.querySelector("#card-details").classList.remove("d-none");
              } else {
                form.submit();
              }
            });
          // Updating a card, or subscribing with a trial (using a SetupIntent)
        } else if (setupIntentId) {
          stripe
            .confirmCardSetup(setupIntentId, {
              payment_method: data.payment_method_data,
            })
            .then((result) => {
              if (result.error) {
                displayError.textContent = result.error.message;
              } else {
                addHiddenField(
                  form,
                  "payment_method_id",
                  result.setupIntent.payment_method
                );
                form.submit();
              }
            });
        } else {
          // Subscribing with no trial
          data.payment_method_data.type = "card";
          stripe.createPaymentMethod(data.payment_method_data).then((result) => {
            if (result.error) {
              displayError.textContent = result.error.message;
            } else {
              addHiddenField(form, "payment_method_id", result.paymentMethod.id);
              form.submit();
            }
          });
        }
      }
    });
  }
}

function addHiddenField(form, name, value) {
  let hiddenInput = document.createElement("input");
  hiddenInput.setAttribute("type", "hidden");
  hiddenInput.setAttribute("name", name);
  hiddenInput.setAttribute("value", value);
  form.appendChild(hiddenInput);
}
