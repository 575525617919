export const openChatBtn = () => {
  $(".contactUsBtn").click(function(event) {
    event.preventDefault();
    $crisp.push(['do', 'chat:open']);
  });
}

export function contactOpenChatBtn () {
  const contactButton = document.getElementById("contact");
  if (contactButton !== null) {
    contactButton.addEventListener("click", (event) => {
      event.preventDefault();
      $crisp.push(['do', 'chat:open']);
    })
  }
}
