import { Controller } from 'stimulus';
import Rails from "@rails/ujs";

export default class extends Controller {

  static targets = ["url", "content"]

  clickOnButton() {
    Rails.ajax({
      type: "post",
      url: this.urlTarget.dataset.url,
      data: `answer[content]=${this.contentTarget.innerHTML.trim()}`,
    })
  }
}
