export const setupSwitch = () => {
  const monthlyPlans = document.getElementById("monthlyPlans");
  const yearlyPlans  = document.getElementById("yearlyPlans");

  $('#switch-plan').on('change', function(e){
    if($(this).prop('checked') == true){
      // Yearly
      $('.old-price').show();

      // Hide monthly plans and show yearly plans
      monthlyPlans.classList.add("d-none")
      yearlyPlans.classList.remove("d-none")

      $( ".price" ).each(function( index ) {
        let min = $(this).data('min');
        $(this).html(min);
      });

    }else{
      // Monthly
      $('.old-price').hide();

      // Hide yearly plans and show monthly plans
      yearlyPlans.classList.add("d-none")
      monthlyPlans.classList.remove("d-none")

      $( ".price" ).each(function( index ) {
        let max = $(this).data('max');
        $(this).html(max);
      });
    }
  });
}
