export function saveChanges(input) {
  const businessId = Number.parseInt(input.dataset.businessId, 10);
  const reviewId = Number.parseInt(input.dataset.reviewId, 10);
  const answerId = Number.parseInt(input.dataset.answerId, 10);
  let change = {
    id:    answerId,
    field: input.id,
    value: input.value || input.dataset.categoryValue,
  };
  $.ajax({
    url: `/businesses/${businessId}/reviews/${reviewId}/answers/${answerId}`,
    type: "patch",
    data: { data_value: JSON.stringify(change) },
  });
}

export function setCategoriesIcons() {
  const categories = document.querySelectorAll('[id^="category"]');
  categories.forEach((category) => {
    if (new RegExp('positi(f|ve)').test(category.dataset.categoryValue)) {
      category.querySelector(":scope > #negative").classList.remove("active");
      category.querySelector(":scope > #positive").classList.add("active");
    } else if (category.dataset.categoryValue == "neutral") {
      category.querySelector(":scope > #negative").classList.remove("active");
      category.querySelector(":scope > #positive").classList.remove("active");
    } else if (new RegExp('n[ée]gati(f|ve)').test(category.dataset.categoryValue)) {
      category.querySelector(":scope > #negative").classList.add("active");
      category.querySelector(":scope > #positive").classList.remove("active");
    }
  })
}

export function setCategoryValue(button) {
  const category          = button.parentElement;
  const positiveCondition = (button.id === "positive" && button.classList.contains("active"));
  const neutralCondition  = (button.id === "positive" && !button.classList.contains("active")) || (button.id === "negative" && !button.classList.contains("active"));
  const negativeCondition = (button.id === "negative" && button.classList.contains("active"));
  if (positiveCondition) {
    category.dataset.categoryValue = "positive";
  } else if (neutralCondition) {
    category.dataset.categoryValue = "neutral";
  } else if (negativeCondition) {
    category.dataset.categoryValue = "negative";
  }
  saveChanges(category);
}

export function listenFeelingFeedbackButtons() {
  const buttons = document.querySelectorAll(".feeling-feedback-button")

  buttons.forEach((button) => {
    button.addEventListener("click", (event) => {
      const container      = button.parentElement;
      const positiveButton = container.querySelector("#positive");
      const negativeButton = container.querySelector("#negative");

      const pressPositiveButton = (button == positiveButton);
      const pressNegativeButton = (button == negativeButton);

      if (pressPositiveButton) {
        positiveButton.classList.toggle("active");
        negativeButton.classList.remove("active");
        setCategoryValue(positiveButton);
        displayGenerateFeedbackButton1();
      } else if (pressNegativeButton) {
        positiveButton.classList.remove("active");
        negativeButton.classList.toggle("active");
        setCategoryValue(negativeButton);
        displayGenerateFeedbackButton1();
      }
    })
 });
}

export function saveNewCategory(category) {
  const businessId = Number.parseInt(category.dataset.businessId, 10);
  const reviewId   = Number.parseInt(category.dataset.reviewId, 10);
  const answerId   = Number.parseInt(category.dataset.answerId, 10);

  const newCategory = {
    name:  category.dataset.categoryName,
    code:  category.dataset.categoryCode,
    label: category.dataset.categoryValue
  };
  let change = {
    id:    answerId,
    field: category.id, // new-category
    value: newCategory,
  };
  $.ajax({
    url: `/businesses/${businessId}/reviews/${reviewId}/answers/${answerId}`,
    type: "patch",
    data: { data_value: JSON.stringify(change) },
  });
}

export function setNewCategoryValue(button) {
  const category          = button.parentElement;
  const newCategoryDiv    = category.parentElement;
  const positiveCondition = (button.id === "positive" && button.classList.contains("active"));
  const neutralCondition  = (button.id === "positive" && !button.classList.contains("active")) || (button.id === "negative" && !button.classList.contains("active"));
  const negativeCondition = (button.id === "negative" && button.classList.contains("active"));
  if (positiveCondition) {
    newCategoryDiv.dataset.categoryValue = "positive";
  } else if (neutralCondition) {
    newCategoryDiv.dataset.categoryValue = "neutral";
  } else if (negativeCondition) {
    newCategoryDiv.dataset.categoryValue = "negative";
  }
  saveNewCategory(newCategoryDiv)
}

export function listenNewFeelingFeedbackButtons() {
  const buttons = document.querySelectorAll(".add-feeling-feedback-button")

  buttons.forEach((button) => {
    button.addEventListener("click", (event) => {
      const container      = button.parentElement;
      const positiveButton = container.querySelector("#positive");
      const negativeButton = container.querySelector("#negative");

      const pressPositiveButton = (button == positiveButton);
      const pressNegativeButton = (button == negativeButton);

      if (pressPositiveButton) {
        positiveButton.classList.toggle("active");
        negativeButton.classList.remove("active");
        setNewCategoryValue(positiveButton);
        displayGenerateFeedbackButton2();
      } else if (pressNegativeButton) {
        positiveButton.classList.remove("active");
        negativeButton.classList.toggle("active");
        setNewCategoryValue(negativeButton);
        displayGenerateFeedbackButton2();
      }
    })
 });
}

export function toggleAnswersDisplay() {
  const displayAnswersButton = document.getElementById("display-answers");

  const answersContainer = document.getElementById("answers");

  const addFeelingOption = document.getElementById("add-feeling");
  const addFeelingButton = document.getElementById("add-feeling-button");

  const newFeelingsContainer = document.getElementById("new-feelings");

  displayAnswersButton.addEventListener("click", (event) => {
    answersContainer.classList.remove("d-none");
    addFeelingOption.classList.remove("d-none");
    displayAnswersButton.classList.add("d-none");
  });
  if(addFeelingButton) {
    addFeelingButton.addEventListener("click", (event) => {
      newFeelingsContainer.classList.remove("d-none");
      addFeelingOption.classList.add("d-none");
    });
  }
}

function displayGenerateFeedbackButton1() {
  const displayAnswersButton = document.getElementById("display-answers");
  const generateAnswerFromFeedbackButton1 = document.getElementById("generate-answer-from-feedback-1");

  const displayAnswersButtonDisplayed        = (!displayAnswersButton.classList.contains("d-none"));
  const noButtonsDisplayedInSentimentSection = (displayAnswersButton.classList.contains("d-none") && generateAnswerFromFeedbackButton1.classList.contains("d-none"));

  if (displayAnswersButtonDisplayed) {
    displayAnswersButton.classList.add("d-none");
    generateAnswerFromFeedbackButton1.classList.remove("d-none");
  } else if (noButtonsDisplayedInSentimentSection) {
    displayGenerateFeedbackButton2();
  }
}

function displayGenerateFeedbackButton2() {
  const generateAnswerFromFeedbackButton2 = document.getElementById("generate-answer-from-feedback-2");

  generateAnswerFromFeedbackButton2.classList.remove("d-none");
}

export function toggleAnswerTraduction() {
  const displayAnswerTraductionButtons = document.querySelectorAll("#display-answer-traduction");

  displayAnswerTraductionButtons.forEach((displayAnswerTraductionButton) => {
    displayAnswerTraductionButton.addEventListener("click", (event) => {
      const container        = displayAnswerTraductionButton.parentElement;
      const reviewLangFlag   = container.querySelector("#flag_review_lang");
      const userLangFlag     = container.querySelector("#flag_user_lang");
      const parentContainer  = container.parentElement;
      const answerTextareas  = parentContainer.querySelectorAll('textarea');
      const reviewLangAnswer = answerTextareas[0];
      const userLangAnswer   = answerTextareas[1];

      // conditions
      const wantToDisplayUserLangAnswer   = (userLangAnswer.classList.contains("d-none"))
      const wantToDisplayReviewLangAnswer = (reviewLangAnswer.classList.contains("d-none"))
      if (wantToDisplayUserLangAnswer) {
        reviewLangFlag.classList.add("d-none");
        reviewLangAnswer.classList.add("d-none");
        userLangFlag.classList.remove("d-none");
        userLangAnswer.classList.remove("d-none");
      } else if (wantToDisplayReviewLangAnswer) {
        userLangFlag.classList.add("d-none");
        userLangAnswer.classList.add("d-none");
        reviewLangFlag.classList.remove("d-none");
        reviewLangAnswer.classList.remove("d-none");
      }
    });
  });
}

export function toggleReviewTraduction() {
  const displayReviewTraductionButton = document.getElementById("display-review-traduction");

  const displayReviewTraductionButtonPresent = (displayReviewTraductionButton !== null)
  if (displayReviewTraductionButtonPresent) {

    displayReviewTraductionButton.addEventListener("click", (event) => {
      const container        = displayReviewTraductionButton.parentElement;
      const reviewLangFlag   = container.querySelector("#flag_review_lang");
      const userLangFlag     = container.querySelector("#flag_user_lang");
      const parentContainer  = container.parentElement;
      const reviewTextareas  = parentContainer.querySelectorAll('textarea');
      const reviewLangReview = reviewTextareas[0];
      const userLangReview   = reviewTextareas[1];

      // conditions
      const wantToDisplayUserLangReview   = (userLangReview.classList.contains("d-none"))
      const wantToDisplayReviewLangReview = (reviewLangReview.classList.contains("d-none"))
      if (wantToDisplayUserLangReview) {
        reviewLangFlag.classList.add("d-none");
        reviewLangReview.classList.add("d-none");
        userLangFlag.classList.remove("d-none");
        userLangReview.classList.remove("d-none");
      } else if (wantToDisplayReviewLangReview) {
        userLangFlag.classList.add("d-none");
        userLangReview.classList.add("d-none");
        reviewLangFlag.classList.remove("d-none");
        reviewLangReview.classList.remove("d-none");
      }
    });
  }
}

export function listenCopyAnswerButtons() {
  const copyAnswerButtons = document.querySelectorAll("#copy-answer-button")

  copyAnswerButtons.forEach((copyAnswerButton) => {
    copyAnswerButton.addEventListener("click", (event) => {
      event.preventDefault();
      const container = copyAnswerButton.parentElement;
      const answers = container.querySelectorAll('textarea');

      answers[0].select();
      document.execCommand("copy");
    });
  });
}
