export function generateAnswer() {
  let connection = new WebSocket(process.env.FAIBRIK_URL); //  Test Feedback wss://cm2-test1.eu-gb.mybluemix.net/review  Original prod: wss://cm2-prod1.eu-de.mybluemix.net/review
  connection.onopen = function () {
    const join = { join: { assistantID: process.env.FAIBRIK_ASSISTANT_ID } };
    connection.send(JSON.stringify(join));
    sendReview(connection);
  };
  connection.onmessage = function (event) {
    let response = JSON.parse(event.data);
    response["source"] = "review";
    listenAnswer(JSON.stringify(response));
  };
}

export function communicateFeedbackAndListenAnswer() {
  let connection = new WebSocket(process.env.FAIBRIK_URL); //  Test Feedback wss://cm2-test1.eu-gb.mybluemix.net/review  Original prod: wss://cm2-prod1.eu-de.mybluemix.net/review
  connection.onopen = function () {
    const join = { join: { assistantID: process.env.FAIBRIK_ASSISTANT_ID } };
    connection.send(JSON.stringify(join));
    sendFeedback(connection);
  };
  connection.onmessage = function (event) {
    let response = JSON.parse(event.data);
    response["source"] = "feedback";
    listenAnswer(JSON.stringify(response));
  };
}
// event.data => answer
function listenAnswer(event) {
  let payload = JSON.parse(event);
  let payload_array = [payload];
  const businessId = Number.parseInt(
    document.querySelector("#review-content").dataset.businessId,
    10
  );
  const reviewId = Number.parseInt(
    document.querySelector("#review-content").dataset.reviewId,
    10
  );
  if (payload.body !== undefined) {
    $.ajax({
      url: `/businesses/${businessId}/reviews/${reviewId}/answers`,
      type: "post",
      data: { data_value: JSON.stringify(payload_array) },
    });
  }
}

function sendReview(connection) {
  const reviewTitle = document.querySelector("#review_title").value;

  const reviewRating = Number.parseInt(
    document.querySelector("#review_rating").value,
    10
  );

  const reviewContent = document.querySelector("#review_content").value;

  const reviewId = Number.parseInt(
    document.querySelector("#review-content").dataset.reviewId,
    10
  );

  const businessCityName = document.getElementById("review_business_address").value;
  const businessName = document.getElementById("review_business_name").value;

  let review = {
    action: "enrich",
    data: {
      assistantID: process.env.FAIBRIK_ASSISTANT_ID,
      msgMax: 3,
      message: reviewContent,
      title: reviewTitle,
      rating: reviewRating,
      trackID: reviewId,
      userLang: "FR",
      context: {
        city_name: businessCityName,
        hotel_name: businessName,
      },
    },
  };
  connection.send(JSON.stringify(review));
}

function sendFeedback(connection) {
  const businessCityName = document.querySelector("#review_business_address")
    .value;
  const businessName = document.querySelector("#review_business_name").value;
  const reviewId = Number.parseInt(
    document.querySelector("#review-content").dataset.reviewId,
    10
  );
  const generalSentiment = document.querySelector("#general_sentiment");
  const uniqueId = Number.parseInt(generalSentiment.dataset.uniqueId, 10);
  const categoriesInputs = document.querySelectorAll(
    "input[data-category-code]"
  );
  const categories = [];
  if (categoriesInputs != null) {
    categoriesInputs.forEach((category) => {
      categories.push({
        code: category.dataset.categoryCode,
        feedback: category.value,
      });
    });
  }

  let feedback = {
    action: "feedback",
    data: {
      type: "review", // Required
      assistantID: process.env.FAIBRIK_ASSISTANT_ID, // Required. Given by fAIbrik
      uniqueID: uniqueId, // Required. Identifier of the message you'd like to give feedback for
      msgMax: 3, // Optional. Number of max message variation you'd like to receive back (default 1)
      reviewLang: "FR", // Two letter code for the language of the review: 'FR', 'DE', 'EN', 'IT' ... If not provided, defaulted to 'FR'
      userLang: "FR", // Two letter code for the language of the user: 'FR', 'DE', 'EN', 'IT'
      trackID: reviewId, // Optional your internal tracking number
      feedback: {
        // Required and must contain generalSentiment and the categories returned from the enriched review
        generalSentiment: generalSentiment.value, // Value of General sentiment, can be 'positive' or 'negative'
        categories: categories,
      },
      context: {
        // Optional. No check on this. Your own set of data that you would like to see substituted in the enriched messages
        city_name: businessCityName,
        hotel_name: businessName,
      },
    },
  };
  connection.send(JSON.stringify(feedback));
}
