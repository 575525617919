import { Controller } from "stimulus"
import TomSelect      from "tom-select"

export default class extends Controller {
  connect() {
    new TomSelect(this.element, {
      sortField: 'text',
      maxOptions: 100
    })
  }
}
