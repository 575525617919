export function setupPicingTab () {
  let switchButton = $("#switch-plan")
  // By default, we set a yearly period

  switchButton.prop("checked", true)
  if ($(".pricingPlan").length) {

    $(".pricingPlan").each(function() {
      let plan                = $(this)

      const monthlyPlans      = $("#monthlyPlans")
      const yearlyPlans       = $("#yearlyPlans")

      const tranquilityCheckBoxes = plan.find($("input"))

      // Setup pricing plan
      setPricingPlan(plan);

      // If click on switch, remove all the data
      switchButton.click(function() {
        $(".serenityCheckbox").prop("checked", false)
        setPricingPlan(plan);
      })

      tranquilityCheckBoxes.click(function(box) {
        setPricingPlan(plan, $(this));
      })

    })
  }
}

function setPricingPlan(plan ,box) {
  const normalPlanLink      = plan.find($(".normalPlan"))
  const tranquilityPlanLink = plan.find($(".tranquilityPlan"))
  const priceInput          = plan.find($(".pricingNormalPrice"))
  const normalPrice         = parseInt(priceInput.text())
  const tranquilityPrice    = parseInt(plan.find($(".pricingTranquilityPrice")).text())
  const minPrice            = parseInt( plan.find(".minPrice").text() )
  const oldPriceInput       = plan.find(".old-price")
  const oldPrice            = parseInt( oldPriceInput.text() )

  // Click on specific box
  if (box && box.is(":checked")) {
    tranquilityPlanLink.removeClass("d-none")
    tranquilityPlanLink.addClass("d-block")
    normalPlanLink.addClass("d-none")
    normalPlanLink.removeClass("d-block")

    if ( oldPriceInput.length ) {
      oldPriceInput.text(`${tranquilityPrice + normalPrice + 10}€`)
    }

    priceInput.text(tranquilityPrice + normalPrice)

  // Setup
  } else {
    tranquilityPlanLink.addClass("d-none")
    tranquilityPlanLink.removeClass("d-block")
    normalPlanLink.removeClass("d-none")
    normalPlanLink.addClass("d-block")

    if ( oldPriceInput.length ) {
      oldPriceInput.text(`${minPrice + 10}€`)
    }

    priceInput.text(minPrice)

  }
}
