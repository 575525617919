import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["review", "input", "flagReviewLang", "flagUserLang", "showTranslationButton", "showOriginalButton", "loader"]
  static values = {
    language: String,
    translated: Boolean,
    original: String,
    translation: String
  }

  translate(event){
    event.preventDefault()
    if (this.translatedValue) {
      if (this.inputTarget.value === this.originalValue) {
        this.inputTarget.value = this.translationValue
        this.#toggleButton()
      } else {
        this.inputTarget.value = this.originalValue
        this.#toggleButton()
      }
    } else {
      this.loaderTarget.style.display="flex"
      const url = `/reviews/${this.reviewTarget.dataset.reviewId}/translate_review`

      fetch(url, { headers: {"Accept": "application/json" } })
        .then(response => response.json())
        .then((data) => {
          this.translatedValue = true
          this.inputTarget.value = data.review.content_vo
          this.translationValue = data.review.content_vo
          this.#toggleButton()
          this.loaderTarget.style.display="none"
        })
    }
  }
  loader(){
    this.loaderTarget.style.display="flex"
  }

  #toggleButton() {
    this.flagReviewLangTarget.classList.toggle("d-none")
    this.flagUserLangTarget.classList.toggle("d-none")
    this.showTranslationButtonTarget.classList.toggle("d-none")
    this.showOriginalButtonTarget.classList.toggle("d-none")
  }
}
