// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// ----------------------------------------------------
// Note(lewagon): ABOVE IS RAILS DEFAULT CONFIGURATION
// WRITE YOUR OWN JS STARTING FROM HERE 👇
// ----------------------------------------------------

// $crisp.push(["safe", true])

// External imports
import "bootstrap";
import Rails from "@rails/ujs";


// Internal imports, e.g:
// import { initSelect2 } from '../components/init_select2';
import "components/pages";

import { generateAnswer, communicateFeedbackAndListenAnswer } from "./faibrik";
import {
  saveChanges,
  setCategoriesIcons,
  listenFeelingFeedbackButtons,
  listenNewFeelingFeedbackButtons,
  listenCopyAnswerButtons,
  toggleAnswersDisplay,
  toggleAnswerTraduction,
  toggleReviewTraduction,
} from "./answers_interactions";
import { initStripe } from "./stripe_integration";
import { setupPicingTab } from "components/pricing-plan";
import { setupSwitch } from "components/switch-plan";
import { openChatBtn, contactOpenChatBtn } from "components/open-chat";

import { dynamicRating } from "./stars_in_review_form";

import 'controllers'

document.addEventListener("turbolinks:load", () => {
  setupPicingTab();
  setupSwitch();
  openChatBtn();
  contactOpenChatBtn();
  dynamicRating();

  let answersContainer = document.querySelector("#answers-container");
  if (answersContainer !== null) {
    generateAnswer();
  }

  let proposedSentiments = document.querySelector("#proposed-sentiments");

  if (proposedSentiments !== null) {
    const answers = document.querySelectorAll('[id^="answer_"]');

    answers.forEach((answer) => {
      answer.addEventListener("input", (event) => {
        saveChanges(event.target);
      });
    });

    setCategoriesIcons()
    listenFeelingFeedbackButtons();
    listenNewFeelingFeedbackButtons();
    listenCopyAnswerButtons();
    toggleAnswersDisplay();
    toggleAnswerTraduction();
    toggleReviewTraduction();
  }

  initStripe();

});
