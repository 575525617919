import { Controller } from "stimulus"

//data-controller="reveal-password"
export default class extends Controller {
  static targets = [ "passwordInput", "showSvg", "hideSvg" ]

  toggle(event) {
    event.preventDefault();

    if (this.passwordInputTarget.type === "password") {
      this.passwordInputTarget.type = "text";
      this.showSvgTarget.classList.add("hidden");
      this.hideSvgTarget.classList.remove("hidden");
    } else {
      this.passwordInputTarget.type = "password";
      this.showSvgTarget.classList.remove("hidden");
      this.hideSvgTarget.classList.add("hidden");
    }
  }
}
