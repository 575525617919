import { Controller } from "stimulus"

//data-controller="pricing-slider"
export default class extends Controller {
  static targets = [ "monthly", "yearly" ]

  toggle(event) {
    this.monthlyTarget.classList.toggle("active");
    this.yearlyTarget.classList.toggle("active");
  }
}
